/*
 * 업무구분: 영업
 * 화 면 명: MSPFS311M
 * 화면설명: 매매신청내역조회2500
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="false">
    <!-- alert modal popup -->
    <fs-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>
    <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top gap40">
          <div class="left column mr0 w40p">
            <div class="row gap40">
              <div class="wrap-input row">
                <label class="emphasis"> 플라자구분 </label>
                <mo-radio-wrapper :items="Rad_dept_gb" v-model="ds_search.dept_gb" class="row"/>
              </div>
            </div>
            <div class="row gap40">
              <div class="wrap-input row">
                <label> 매입기준 </label>
                <mo-dropdown :items="Cob_buy_req_gb" v-model="ds_search.buy_req_gb" class="w130" placeholder="선택하세요" />
              </div>
            </div>
            <div class="row">
              <div class="wrap-input row">
                <label class="emphasis"> 기준일 </label>
                <mo-date-picker
                  ref="calFrom"
                  v-model="cal_from"
                  :bottom="false"
                  class="w110"
                  :class="cal_from_error ? 'error' : ''"
                  no-placeholder
                  @input="fn_dateCheck"
                  /> <!-- 시작일 -->
                <i> ~ </i>
                <mo-date-picker
                  ref="refTo"
                  v-model="cal_To"
                  :bottom="false"
                  class="w110"
                  :class="cal_To_error ? 'error' : ''"
                  no-placeholder
                  @input="fn_dateCheck"
                  /> <!-- 종료일 -->
                <!-- [D] 기준 시작일과 종료일 입력 시, 시작일보다 종료일이 작을 때나 종료일보다 시작일이 클 때는 알림(토스트팝업) 후 초기값(오늘날짜) 작성 -->
                <!-- <mo-range-picker v-model="cal_fromTo" :bottom="false" class="w130" /> -->
              </div>
            </div>
            <div class="row w100p">
              <div class="wrap-input row">
                <label class="emphasis"> 구분 </label>
                <mo-radio-wrapper :items="Rad_trust_gb" v-model="ds_search.vip_gb" class="row"/>
              </div>
            </div>
          </div>
          <div class="right column gap10 w60p">
            <div class="row">
              <div class="wrap-input row">
                <label class="emphasis"> 영업일구분 </label>
                <mo-radio-wrapper :items="Rad_trd_gb" v-model="ds_search.biz_dt_gb" class="row"/>
              </div>
            </div>
            <div class="row">
              <div class="wrap-input row">
                <label class="emphasis"> 플라자코드 </label>
                <mo-text-field
                v-model="ds_search.dept_cd"
                @input="fn_searchPlaza"
                :disabled="dept_cd_disabled"
                maxlength="3"
                ref="deptCd"
                class="w130"
                type="number"
                :class="dept_cd_error ? 'error' : ''"
                />
                <mo-button @click="fn_OpenPopup(0)" :disabled="btn_save1"> 플라자 </mo-button>
                <mo-text-field v-model.trim="ds_search.dept_nm" class="w150" disabled />
              </div>
            </div>
            <div class="row gap40">
              <div class="wrap-input row">
                <label> 펀드유형 </label>
                <mo-dropdown :items="lv_commCode.code107" v-model="ds_search.fund_tp3" class="w150" placeholder="전체" />
                <!-- <mo-text-field v-model="text03" class="w100" /> -->
              </div>
              <div class="wrap-input row">
                <label> 유선여부 </label>
                <mo-dropdown :items="Cob_tel_use_yn" v-model="ds_search.tel_use_yn" class="w100" placeholder="전체" disabled />
              </div>
            </div>
            <div class="row w100p justify-between">
              <div class="wrap-input row">
                <label> 매체구분 </label>
                <mo-dropdown :items="Cob_media_gb" v-model="ds_search.media_gb" class="w150" placeholder="전체" />
                <!-- <mo-text-field v-model="text04" class="w100" /> -->
              </div>
              <div class="wrap-button row">
                <mo-button @click="fn_init"> 초기화 </mo-button>
                <mo-button primary @click="fn_searchList"> 조회 </mo-button>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>
      <ur-box-container direction="column" alignV="start">
        <div class="right-bg-white mt16">
          <div class="wrap-table x-scroll h-scroll">
            <table class="table row-type wsN">
              <thead>
                <tr>
                  <th class="w50"> LT </th>
                  <th class="w95"> 고객명 </th>
                  <th class="w140"> 계좌번호 </th>
                  <th> 펀드명 </th>
                  <th class="w100"> 신청일 </th>
                  <th class="w100"> 결제예정일 </th>
                  <th class="w110"> 신청금액 </th>
                  <th class="w110"> 결제좌수 </th>
                  <th> 신청점 </th>
                  <th> 관리점 </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, i) in ds_list" :key="i">
                  <td> {{row.late_trading_yn}} </td>
                  <td> {{row.cust_nm}} </td>
                  <td> {{fn_acctNoDashFormat(row.acct_no)}} </td>
                  <td> {{row.fund_nm}} </td>
                  <td> {{$bizUtil.dateDashFormat(row.trd_dt)}} </td>
                  <td> {{$bizUtil.dateDashFormat(row.setl_plan_dt)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(row.req_amt)}} </td>
                  <td class="aR"> {{$bizUtil.numberWithCommas(row.setl_qty)}} </td>
                  <td> {{row.req_dept_nm}} </td>
                  <td> {{row.dept_nm}} </td>
                </tr>
              </tbody>
              <tfoot v-if="ds_list.length > 0">
                <tr>
                  <td>합계</td>
                  <td></td>
                  <td class="aR">{{$bizUtil.numberWithCommas(ds_list.length)}}건</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="aR">{{$bizUtil.numberWithCommas(req_amt_sum)}}</td>
                  <td class="aR">{{$bizUtil.numberWithCommas(setl_qty_sum)}}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </ur-box-container>

    </main>
    <!-- //.fts-main -->
    <!-- popup311 -->
    <msp-fs-311p
      ref="popup311"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup311Obj"
    ></msp-fs-311p>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역
***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import moment from 'moment'

import MSPFS311P from '~/src/ui/fs/MSPFS311P'

export default {
  /***********************************************************************************
     * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  name: 'MSPFS311M',
  screenId: 'MSPFS311M',
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'msp-fs-311p': MSPFS311P
  },
  /***********************************************************************************
     * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created () {
    this.fn_SetCommCode()
  },
  mounted () {
    this.$bizUtil.insSrnLog(this.$options.screenId)

    // 파라미터가 있는 경우
    if ( !FSCommUtil.gfn_isNull(this.$route.params) ) {
      setTimeout(() => {
        // this.lv_cob_rname_gb.value = this.$route.params.cob_rname_gb
        // this.lv_ed_rname_no.value1 = this.$route.params.ed_rname_no[0]
        // this.lv_ed_rname_no.value2 = this.$route.params.ed_rname_no[1]
        console.log('파라미터있음 $routeParm===', this.$route.params)
        this.fn_paramSetting(this.$route.params)
      }, 100)
    }
    this.fn_init()
  },
  /***********************************************************************************
     * 화면변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      ds_list: [],
      req_amt_sum: 0, // 신청금액 합계
      setl_qty_sum: 0, // 결제좌수 합계
      /* as-is 전역변수 */
      svcId: '',
      fndCdLen: '', // this.gfn_userinfo("fndCdLen"),
      gFundAllSearch: '',
      gFundSearch: '9999',
      brcd: '', // this.gfn_userinfo("brcd");
      brNm: '', // this.gfn_userinfo("brNm");
      disp_fof_cd: '', // this.gfn_userinfo("disp_fof_cd");
      disp_fof_nm: '', // this.gfn_userinfo("disp_fof_nm");
      hdshp_yn: '', // = this.gfn_userinfo("hdshp_yn");
      /* as-is 전역변수 끝 */
      // 공통 객체
      pHeaderObj: {
        title: '매입신청내역조회',
        step: 10
      },
      // alert
      pAlertPopupObj: {},
      pPopup311Obj: {},
      // 기준일 from~to
      cal_from: '',
      cal_To: '',

      // ds_search
      ds_search: {
        fromstd_dt: '', // 기준일 from
        tostd_dt: '', // 기준일 to
        proc_gb: '',
        cust_no: '', // 고객번호
        inv_empno: '', // 권유자사번
        dept_gb: '14', // 플라자구분
        dept_cd: '', // 플라자코드
        fund_cd: '', // 펀드코드
        biz_dt_gb: '11', // 영업일구분
        media_gb: '0', // 매체구분
        buy_req_gb: '0', // 매입기준
        fund_tp3: 'ALL', // 펀드유형
        cdsc_conv_groupv: '',
        tel_use_yn: 'A', // 유선여부 - 관리자화면(2501)에서만 사용 현재 페이지에서는 비활성화
        vip_gb: 'G', // 구분
        dept_nm: '', // 관리점명
        dept_gb_nm: '',
        biz_dt_gb_nm: '',
        rad_person_gb: ''
      },
      // 부지점 disabled
      dept_cd_disabled: false,
      btn_save1: false,
      dept_cd_error: false,
      // 기준일
      cal_from_error: false,
      cal_To_error: false,

      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
      /***********************************************************************************
         * 공통코드
      ************************************************************************************/
      lv_commCode: {}
    }
  },
  /***********************************************************************************
     * Computed 함수 정의 영역
  ***********************************************************************************/
  computed: {
    Rad_dept_gb () {
      let rtn = []
      rtn.push({value: '14', text: '신청점'})
      rtn.push({value: '11', text: '관리점'})
      return rtn
    },
    Rad_trust_gb () {
      let rtn = []
      rtn.push({value: 'G', text: '일반'})
      rtn.push({value: 'T', text: '전체(신탁포함)'})
      return rtn
    },
    Rad_trd_gb () {
      let rtn = []
      rtn.push({value: '11', text: '신청일'})
      rtn.push({value: '12', text: '결제일'})
      return rtn
    },
    Cob_buy_req_gb () {
      let rtn = []
      rtn.push({value: '0', text: '전체'})
      rtn.push({value: '1', text: '일반'})
      rtn.push({value: '2', text: 'CMS이체'})
      rtn.push({value: '3', text: '자동대체'})
      rtn.push({value: '4', text: '급여이체'})
      rtn.push({value: '5', text: '이동입고'})
      rtn.push({value: '6', text: '연금이전'})
      return rtn
    },
    Cob_tel_use_yn () {
      let rtn = []
      rtn.push({value: 'A', text: '전체'})
      rtn.push({value: 'N', text: '일반'})
      rtn.push({value: 'Y', text: '유선'})
      rtn.push({value: 'E', text: '기타'})
      return rtn
    },
    Cob_media_gb () {
      let rtn = []
      rtn.push({value: '0', text: '전체'})
      rtn.push({value: '1', text: '영업점'})
      rtn.push({value: '2', text: '인터넷'})
      rtn.push({value: '4', text: '모바일'})
      rtn.push({value: '5', text: '태블릿내방'})
      rtn.push({value: '6', text: '태블릿방판'})
      return rtn
    }
  },
  /***********************************************************************************
     * watch 함수 정의 영역
  ***********************************************************************************/
  watch: {},
  /***********************************************************************************
     * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
       * Function명 : fn_historyBack
       * 설명       : 뒤로가기 버튼
    ******************************************************************************/
    fn_HistoryBack () {
      this.$router.go(-1)
    },
    /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
    ******************************************************************************/
    fn_SetCommCode () {
      console.log('fn_SetCommCode...')

      let params = [
        {'cId': '107', 'dayChkYn': 'N'}
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },

    fn_SetCommCodeResult (pResult) {
      this.lv_commCode = pResult
    },

    fn_init () {
      this.fndCdLen = this.lv_basInfo.fndCdLen// this.gfn_userinfo("fndCdLen"),
      this.gFundAllSearch = ''
      this.gFundSearch = '9999'
      this.brcd = this.lv_basInfo.brcd
      this.brNm = this.lv_basInfo.brNm // this.gfn_userinfo("brNm");
      this.disp_fof_cd = this.lv_basInfo.disp_fof_cd // this.gfn_userinfo("disp_fof_cd");
      this.disp_fof_nm = this.lv_basInfo.disp_fof_nm // this.gfn_userinfo("disp_fof_nm");
      this.hdshp_yn = this.lv_basInfo.hdshp_yn // = this.gfn_userinfo("hdshp_yn");
      // 초기화
      this.ds_list = []

      this.ds_search.proc_dt = this.lv_basInfo.busyDate// ComDate.gfn_getBusyDate();

      this.ds_search = {
        fromstd_dt: '', // 기준일 from
        tostd_dt: '', // 기준일 to
        proc_gb: '',
        cust_no: '', // 고객번호
        inv_empno: '', // 권유자사번
        dept_gb: '14', // 플라자구분
        dept_cd: '', // 플라자코드
        fund_cd: '', // 펀드코드
        biz_dt_gb: '11', // 영업일구분
        media_gb: '0', // 매체구분
        buy_req_gb: '0', // 매입기준
        fund_tp3: 'ALL', // 펀드유형
        cdsc_conv_groupv: '',
        tel_use_yn: 'A', // 유선여부 - 관리자화면(2501)에서만 사용 현재 페이지에서는 비활성화
        vip_gb: 'G', // 구분
        dept_nm: '', // 관리점명
        dept_gb_nm: '',
        biz_dt_gb_nm: '',
        rad_person_gb: ''
      }
      this.ds_list = []
      this.req_amt_sum = 0
      this.setl_qty_sum = 0

      console.log('this.disp_fof_cd???', this.disp_fof_cd)
      this.ds_search.dept_cd = this.disp_fof_cd
      this.ds_search.dept_nm = this.disp_fof_nm

      // 부지점 disabled
      this.dept_cd_disabled = false
      this.btn_save1 = false
      this.dept_cd_error = false
      // 기준일
      this.cal_from_error = false
      this.cal_To_error = false
      this.cal_from_error = false
      this.cal_To_error = false

      if (this.fndCdLen === '4') {
        this.gFundAllSearch = '9999'
      } else if (this.fndCdLen === '5') {
        this.gFundAllSearch = '99999'
      } else if (this.fndCdLen === '6') {
        this.gFundAllSearch = '999999'
      }

      if (this.brcd !== '905') {
        // test
        this.dept_cd_disabled = true
        this.btn_save1 = true
      }

      console.log('날짜가져오기', moment(new Date()).format('YYYY-MM-DD'))
      this.cal_To = FSCommUtil.gfn_dateReplace(1, FSCommUtil.gfn_strToday(1))
      this.cal_from = FSCommUtil.gfn_dateReplace(1, FSCommUtil.gfn_strToday(1))
    },

    /***************************************************************************************
       * Function명 : fn_paramSetting
       * 설명       : 초기화
    *****************************************************************************************/
    fn_paramSetting (paObjList) {
      if (!FSCommUtil.gfn_isNull(paObjList)) {
        var pScrNo = ''
        // var pCustNo = ''
        var pTrdGb = ''
        var pFundCd = ''
        var pFromDt = ''
        var pToDt = ''
        var pBuyReqGb = ''
        var pDeptCd = ''

        if (!FSCommUtil.gfn_isNull(paObjList.rmvl_srn_id)) {
          pScrNo = paObjList.rmvl_srn_id
        }
        if (!FSCommUtil.gfn_isNull(paObjList.bfcer_cust_no)) {
          // pCustNo = paObjList.bfcer_cust_no
        }
        if (!FSCommUtil.gfn_isNull(paObjList.trd_ymd_sc_cd)) {
          pTrdGb = paObjList.trd_ymd_sc_cd
        }
        if (!FSCommUtil.gfn_isNull(paObjList.bfcer_fnd_cd)) {
          pFundCd = paObjList.bfcer_fnd_cd
        }
        if (!FSCommUtil.gfn_isNull(paObjList.inqr_str_ymd)) {
          pFromDt = paObjList.inqr_str_ymd
        }
        if (!FSCommUtil.gfn_isNull(paObjList.inqr_end_ymd)) {
          pToDt = paObjList.inqr_end_ymd
        }
        if (!FSCommUtil.gfn_isNull(paObjList.bfcer_buy_apl_sc_cd)) {
          pBuyReqGb = paObjList.bfcer_buy_apl_sc_cd
        }
        if (!FSCommUtil.gfn_isNull(paObjList.bfcer_fof_cd)) {
          pDeptCd = paObjList.bfcer_fof_cd
        }

        console.log('pScrNo?????', pScrNo)
        if (pScrNo === 'SI481000') {
          this.ds_search.trd_gb = pTrdGb
          this.ds_search.fund_cd = pFundCd

          this.cal_from = pFromDt
          this.cal_To = pToDt
          this.ds_search.buy_req_gb = pBuyReqGb
          this.ds_search.dept_gb = '11'
          this.ds_search.dept_cd = pDeptCd

          if (!FSCommUtil.gfn_isNull(pDeptCd)) {
            this.fn_searchPlaza()
          }
        }

        if (pScrNo === 'IN383500') {
          this.ds_search.dept_cd = pDeptCd
          if (!FSCommUtil.gfn_isNull(pDeptCd)) {
            this.fn_searchPlaza()
          }
        }
      }
      // this.gfn_clearFrameAguments("FORM_PARAM");
    },

    /***************************************************************************************
       validation 체크
    *****************************************************************************************/
    fn_validateForm () {
      var fromDate = moment(this.cal_from).format('YYYYMMDD')
      var toDate = moment(this.cal_To).format('YYYYMMDD')
      var vMaxMm = moment(fromDate).add(1, 'months').format('YYYYMMDD')

      if (vMaxMm < toDate) {
        console.log()
        FSCommUtil.gfn_validate(this, '조회기간은 최대 1개월까지 가능합니다.')
        this.cal_from_error = true
        this.cal_To_error = true
        // this.$refs['calFrom'].focus()
        console.log(vMaxMm)
        return false
      }
      if (FSCommUtil.gfn_length(this.ds_search.dept_cd) !== 3 ||
         FSCommUtil.gfn_length(this.ds_search.dept_cd) === 0 ||
         FSCommUtil.gfn_isNull(this.ds_search.dept_nm)) {
        FSCommUtil.gfn_validate(this, '플라자코드를 입력하십시오.')
        this.dept_cd_error = true
        this.$refs['deptCd'].focus()
        return false
      }
      if (fromDate > toDate) {
        FSCommUtil.gfn_validate(this, '시작일이 큰수입니다. 조회일을 확인하여 주세요')
        this.cal_from_error = true
        // this.$refs['calFrom'].focus()
        return false
      }

      return true
    },

    /***************************************************************************************
       매입신청내역 조회
    *****************************************************************************************/
    fn_searchList () {
      if (!this.fn_validateForm()) {
        return
      }
      console.log('매입신청내역')
      var eai = ''
      this.ds_search.fromstd_dt = this.cal_from
      this.ds_search.tostd_dt = this.cal_To
      // C391_F10570218_S : 매입신청내역날짜조회, C391_F10570217_S : 매입신청내역고객조회
      if (this.ds_search.person_gb === '1') { // 매입고객조회
        eai = 'C391_F10570217_S'

        this.ds_search.proc_gb = 'S1'
        this.ds_search.cust_no = this.ds_search.cust_cd
      } else if (this.ds_search.person_gb === '2') { // 매입고객조회
        eai = 'C391_F10570217_S'

        this.ds_search.proc_gb = 'S2'
        this.ds_search.inv_empno = this.ds_search.emp_cd
      } else { // 매입날짜조회
        eai = 'C391_F10570218_S'

        this.ds_search.proc_gb = 'S'
        if (this.ds_search.fund_cd === this.gFundAllSearch) {
          this.ds_search.fund_cd = this.gFundSearch
        }
      }

      // 서버에 호출 할 정보를 셋팅 해준다.
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = eai
      this.eaiCommObj.params = {
        biz_dt_gb: this.ds_search.biz_dt_gb, // 영업일 구분, Length : 4
        biz_dt_gb_nm: this.ds_search.biz_dt_gb_nm, // 영업일 구분 명, Length : 256 moment(date.periodStart).format('YYYYMMDD')
        buy_req_gb: this.ds_search.buy_req_gb, // 매입신청구분, Length : 4
        cust_no: this.ds_search.cust_no, // 고객번호, Length : 7
        dept_cd: this.ds_search.dept_cd, // 기관코드, Length : 9
        dept_gb: this.ds_search.dept_gb, // 기관구분, Length : 4
        dept_gb_nm: this.ds_search.dept_gb_nm, // 영업점 구분 명, Length : 256
        dept_nm: this.ds_search.dept_nm, // 영업점 명, Length : 256
        fromstd_dt: moment(this.cal_from).format('YYYYMMDD'), // FROM 기준일, Length : 8
        fund_cd: '', // 펀드코드, Length : 12
        fund_tp3: this.ds_search.fund_tp3, // 펀드유형3, Length : 1
        inv_empno: '', // 권유자 사번, Length : 30
        media_gb: this.ds_search.media_gb, // 매체구분, Length : 1
        proc_gb: this.ds_search.proc_gb, // 프로세스 구분, Length : 1
        rad_person_gb: '', // 고객 구분 명, Length : 256
        tostd_dt: moment(this.cal_To).format('YYYYMMDD'), // TO 기준일, Length : 8
        vip_gb: this.ds_search.vip_gb, // 구분, Length : 4
        cdsc_conv_group: ''
      }

      this.ds_list = []
      this.req_amt_sum = 0
      this.setl_qty_sum = 0

      console.log('등록params', this.eaiCommObj.params)
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },

    /***************************************************************************************
      CALLBACK
    *****************************************************************************************/
    fn_callBack (response) {
      console.log('매입조회 response ====', response)
      console.log('트랜ID', this.eaiCommObj.commHeaderVO.eaiId)
      // this.Div00.div_btnList.btn_searchList.set_enable(true);
      this.btn_searchList = false // disabled
      var data = response.data.rp250000_O_00VO
      if (FSCommUtil.gfn_length(data) > 0) {
        this.ds_list = data

        // 합계 구하기
        this.ds_list.forEach((item, idx) => {
          this.req_amt_sum = Number(item.req_amt) + this.req_amt_sum
          this.setl_qty_sum = Number(item.setl_qty) + this.setl_qty_sum
        })
      }
      console.log('매입조회 리스트 this.ds_list== ', this.ds_list)
    },

    /******************************************************************************
       * Function명 : fn_dateCheck
       * 설명       : from ~ to 날짜 변경 시
    ******************************************************************************/
    fn_dateCheck () {
      if (this.cal_from.replace(/-/g, '') > this.cal_To.replace(/-/g, '')) {
        this.cal_To = FSCommUtil.gfn_dateReplace(1, FSCommUtil.gfn_strToday(1))
        this.cal_from = FSCommUtil.gfn_dateReplace(1, FSCommUtil.gfn_strToday(1))
        FSCommUtil.gfn_validate(this, '날짜 형식에 맞지 않습니다.')
      }
    },

    /******************************************************************************
       * Function명 : fn_acctNoDashFormat
       * 설명       : 계좌번호 처리
    ******************************************************************************/
    fn_acctNoDashFormat (acctNo) {
      acctNo = FSCommUtil.gfn_substr(acctNo, 0, 3)  + '****-*' + FSCommUtil.gfn_substr(acctNo, 8, 2) + '-' + FSCommUtil.gfn_substr(acctNo, 10, 3)
      return acctNo
    },

    /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type) : 0: 부지점
    ******************************************************************************/
    fn_Popup_CallBack (type, pData) {
      switch (type) {
        case 0:
          // console.log('popup callback...', JSON.stringify(pData));
          if (pData.bfcer_fof_cd !== undefined && pData.bfcer_fof_cd !== '') {
            this.ds_search.dept_cd = pData.bfcer_fof_cd
            this.ds_search.dept_nm = pData.bfcer_fof_nm
          }
          break
      }
    },

    /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type) :
    ******************************************************************************/
    fn_OpenPopup (type) {
      switch (type) {
        case 0:
          this.$refs.popup311.fn_Open()
          break
      }
    },

    /***********************************************************************************
      * Function명 : fn_searchPlaza
      * 설명       : 영업점 조회
    ***********************************************************************************/
    fn_searchPlaza () {
      this.dept_cd_error = false
      if (FSCommUtil.gfn_length(this.ds_search.dept_cd) < 3) {
        this.ds_search.dept_nm = ''
        return
      }
      // 서비스 호출
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570271_S'
      this.eaiCommObj.params = {
        bfcer_fof_cd: this.ds_search.dept_cd,
        bfcer_fof_nm: ''
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBackPlaza)
    },

    fn_callBackPlaza (response) {
      var data = response.data.sy000400_O_00VO[0]
      console.log('부점 조회 data ', data )
      if (!FSCommUtil.gfn_isNull(data) || data !== undefined) {
        this.ds_search.dept_cd = data.bfcer_fof_cd
        this.ds_search.dept_nm = data.bfcer_fof_nm
      } else {
        FSCommUtil.gfn_validate(this, '부점이 존재하지 않습니다.')
        this.ds_search.dept_cd = ''
        this.ds_search.dept_nm = ''
      }
    },
    /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
    ******************************************************************************/
    fn_AlertPopup (type, pPopupObj) {
      if ( !FSCommUtil.gfn_isNull(pPopupObj) ) {
        this.pAlertPopupObj.content = pPopupObj.content
        this.pAlertPopupObj.confirm = false
        this.pAlertPopupObj.cancel = true

        if ( !pPopupObj.cancel ) {
          this.pAlertPopupObj.cancel = pPopupObj.cancel
        }
        if ( pPopupObj.confirm ) {
          this.pAlertPopupObj.confirm = pPopupObj.confirm
          this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
          this.pAlertPopupObj.confirmData = pPopupObj.confirmData
        }
        if ( pPopupObj.closeFunc ) {
          this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
          this.pAlertPopupObj.closeData = pPopupObj.closeData
        }
      }
      this.$refs.alertPopup.fn_Open()
    }
  }

}
</script>
<style scoped>
</style>
